.leftbar{
    
    display:flex;
    width:300px;
    height:100%;
    background: #F8F9FB;
    height:100vh !important;

}

.leftbarListItem{
    color: #555;
    margin: 0;
   font-size: 15px;
  
}

.leftbar li {
    list-style: none;
 
}

.leftbarWrapper{

}

.leftbarMenu{
    margin-bottom: 10px;
  
}

.leftbarTittle{
    font-size: 13px;
    color: rgb(43, 43, 43);
}


.leftbarList{
    padding-left: 0;
 
}

.leftbarListItem{
 padding-bottom:15px ;
 align-items: center;
 display: flex;
 cursor: pointer;
 border-radius:4px ;

}

.leftbarListItem:hover{

  background-color: rgb(240, 240, 240);
   }
   
li.leftbarListItem{
    padding:5px 15px;
}