.image-Productdetail{
    width:33rem;
 
}


.sss{
    font-weight:normal;
}

img{
 max-height:500px !important;
}