.card-items{
    flex-direction: row;
    border: 1px solid #d8d8d8;
}


li.card-items{
    list-style-type: none;
}

.card-img{
    max-height: auto;
    max-width: 165px;
}